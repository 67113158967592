//// Manage sessions across *.mgka.net subdomains

const API_HOST = 'https://api.mgka.net/auth';

// check if we have an existing active session & get a token for it
export async function fetchFirebaseToken() {
    const response = await fetch(`${API_HOST}/get-token`, {
        method: 'GET',
        credentials: 'include'  // to send the cookie along with the request
    });
    if (response.ok) {
        const data = await response.json();
        return data.firebaseToken;
    } else if (response.status === 401) {
        // session expired, need to re-auth
        console.warn('Session expired, need to re-auth');
        throw new Error('Session expired');
    } else if (response.status === 404) {
        // no session found
        console.warn('No session found');
        throw new Error('No session found');
    } else if (response.status === 500) {
        // server error
        console.error('Server error:', response.statusText);
        throw new Error('Server error');
    }
}

export async function postIdTokenToSessionLogin(idToken) {
    const response = await fetch(`${API_HOST}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include', // Ensure cookies are sent and received
        body: JSON.stringify({ idToken })
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
}