import '@picocss/pico/css/pico.min.css';
import { signInWithGoogle, signInWithMicrosoft, signInWithPassword, signInWithToken } from './utils/providers';
import { fetchFirebaseToken } from './utils/session';

function toggleLoginButtonsDisabled(disabled) {
    document.getElementById('login-google').setAttribute('disabled', disabled);
    document.getElementById('login-msft').setAttribute('disabled', disabled);
    document.getElementById('login-pw').setAttribute('disabled', disabled);
    document.getElementById('login-pw-submit').setAttribute('disabled', disabled);
}
// configure event listeners for buttons etc
async function configureEventListeners() {
    document.getElementById('login-google').addEventListener('click', async () => {
        document.getElementById('login-google').setAttribute('aria-busy', 'true');
        toggleLoginButtonsDisabled(true);
        await signInWithGoogle().then(async () => {
            await checkForRedirect(); // check first, may be no need to displat user info on this page
            await checkForCodeGeneration();
            successfulLogin();
        }).catch((error) => {
            alert(error);
        });
        document.getElementById('login-google').setAttribute('aria-busy', 'false');
        toggleLoginButtonsDisabled(false);
    });

    document.getElementById('login-msft').addEventListener('click', async () => {
        document.getElementById('login-msft').setAttribute('aria-busy', 'true');
        toggleLoginButtonsDisabled(true);
        await signInWithMicrosoft().then(async () => {
            await checkForRedirect(); // check first, may be no need to displat user info on this page
            await checkForCodeGeneration();
            successfulLogin();
        }).catch((error) => {
            alert(error);
        });
        document.getElementById('login-msft').setAttribute('aria-busy', 'false');
        toggleLoginButtonsDisabled(false);
    });

    document.getElementById('login-pw').addEventListener('click', async () => {
        // hide the buttons, show the form
        document.getElementById('sso-buttons').style.display = 'none';
        document.getElementById('login-pw-container').style.display = 'block';
    });

    document.getElementById('login-pw-sso').addEventListener('click', async () => {
        // hide the form, show the buttons
        document.getElementById('login-pw-container').style.display = 'none';
        document.getElementById('sso-buttons').style.display = 'block';
        // clear form
        document.getElementById('login-pw-form').elements.login.value = '';
        document.getElementById('login-pw-form').elements.password.value = '';
    });

    // on form submit, log in with password
    document.getElementById('login-pw-form').addEventListener('submit', async (e) => {
        document.getElementById('login-pw-submit').setAttribute('aria-busy', 'true');
        toggleLoginButtonsDisabled(true);
        console.log('logging in with password');
        e.preventDefault();
        const form = e.target;

        await signInWithPassword(form.elements.login.value, form.elements.password.value).then(async () => {
            await checkForRedirect(); // check first, may be no need to displat user info on this page
            await checkForCodeGeneration();
            successfulLogin();
            // hide and clear form
            document.getElementById('login-pw-container').style.display = 'none';
            form.elements.login.value = '';
            form.elements.password.value = '';
        }).catch((error) => {
            alert(error);
        });
        document.getElementById('login-pw-submit').setAttribute('aria-busy', 'false');
        toggleLoginButtonsDisabled(false);
    });
}

document.addEventListener('DOMContentLoaded', async () => {
    console.log('hello world!');
    await configureEventListeners();

    console.log('Checking for existing session...');

    await fetchFirebaseToken().then(async (token) => {
        console.log('Existing session found');

        await signInWithToken(token).then(async () => {
            await checkForRedirect(); // check first, may be no need to displat user info on this page
            await checkForCodeGeneration();
            successfulLogin();
        }).catch((error) => {
            console.log('Error signing in with token:', error);
            // need to re-auth - hide the progress, show the buttons
            document.getElementById('login-progress').style.display = 'none';
            document.getElementById('login-options').style.display = 'block';
        });

    }).catch((error) => {
        console.log('No existing session found:', error);
        // no existing session - show the buttons
        document.getElementById('login-progress').style.display = 'none';
        document.getElementById('login-options').style.display = 'block';
    });
});


// if code param, we nede to generate a code
async function checkForCodeGeneration() {
    console.log('Checking for code generation...');
    const url = new URL(window.location.href);
    const code = url.searchParams.get('code');
    if (code) {
        await generateCode();
    }
}

async function generateCode() {
    console.log('Generating code...');

    // API request to /generate-code - uses the cookie to identify the user
    const response = await fetch('https://api.mgka.net/generate-code', {
        method: 'GET',
        credentials: 'include', // Ensure cookies are sent and received
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log(data);

    const code = data.code;
    const expiryTime = data.expiryTime; // in milliseconds

    // hide progress, show code in message
    document.getElementById('login-progress').style.display = 'none';
    document.getElementById('message').style.display = 'block';

    // count down the seconds until expiry
    // count down the seconds until expiry
    const countDown = setInterval(async () => {
        const currentTime = Date.now();
        const timeLeft = Math.floor((expiryTime - currentTime) / 1000); // calculate seconds left

        if (timeLeft <= 0) {
            document.getElementById('message').innerHTML = '<p>Login Code: <strong>Expired</strong></p>';
            document.getElementById('copy-code').style.display = 'block';
            clearInterval(countDown);
            return;
        }

        try {
            const [valid, error] = await checkCodeValidity(code);

            if (!valid) {
                if (error === 'Code already used') {
                    document.getElementById('message').innerHTML = '<p><strong>Login Complete</strong> <span style="color:green;">&#10003;</span></p>';
                } else if (error === 'Code expired') {
                    document.getElementById('message').innerHTML = '<p>Login Code: <strong>Expired</strong></p>';
                } else {
                    document.getElementById('message').innerHTML = `<p>Login Code: <strong>Error</strong></p><br>${error}`;
                }
                document.getElementById('copy-code').style.display = 'none';
                clearInterval(countDown);
                return;
            }

        } catch (error) {
            document.getElementById('message').innerHTML = `<p>Login Code: <strong>Error</strong></p><br>${error.message}`;
            document.getElementById('copy-code').style.display = 'block';
            clearInterval(countDown);
            return;
        }

        document.getElementById('message').innerHTML = `<p>Login Code: <strong>${code}</strong></p><br>This code is valid for ${timeLeft} seconds.`;
        document.getElementById('copy-code').style.display = 'block';
    }, 1000);

    // show copy-code
    document.getElementById('copy-code-link').addEventListener('click', async () => {
        await navigator.clipboard.writeText(code);
        document.getElementById('copy-code-link').innerHTML = 'Copied!';
        setTimeout(() => {
            document.getElementById('copy-code-link').innerHTML = 'Copy';
        }, 1000);
    });

}


// API req to /check-code-valid - uses the cookie to identify the user
async function checkCodeValidity(code) {
    console.log('Checking code validity...');

    // API request to /check-code-valid - uses the cookie to identify the user
    const response = await fetch('https://api.mgka.net/check-code-valid', {
        method: 'POST',
        credentials: 'include', // Ensure cookies are sent and received
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code })
    });

    const data = await response.json();

    if (!response.ok) {
        return [data.valid, data.error];
    }

    return [data.valid, data.error];
}

// check for a redirect param to go back to the app
async function checkForRedirect() {
    console.log('Checking for redirect...');
    const url = new URL(window.location.href);
    const redirect = url.searchParams.get('redirect');
    if (redirect) {
        window.location.href = redirect;
    }
}

// do something here in the future
async function successfulLogin() { }