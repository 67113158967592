import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyCj6olj5kvdGhl32pJlxL8wq20oxIWWmpo',
    authDomain: 'auth.mgka.net',
    projectId: 'slam4edu',
    storageBucket: 'slam4edu.appspot.com',
    messagingSenderId: '640905549057',
    appId: '1:640905549057:web:49c00dd94e299ff448b700'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


export { app, auth };