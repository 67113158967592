import { GoogleAuthProvider, signInWithCustomToken, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth } from './firebase';
import { postIdTokenToSessionLogin } from './session';

export function signInWithGoogle() {
    return new Promise((resolve, reject) => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then(async function (result) {
            console.log(`Authed with ${result.user.email}`);
            const token = await result.user.getIdToken(true);
            postIdTokenToSessionLogin(token).then((data) => {
                console.log(data);
                resolve(result.user);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        }).catch(function (error) {
            console.error(error);
            reject(error);
        });
    });
}

export function signInWithMicrosoft() {
    return new Promise((resolve, reject) => {
        reject('Not implemented because Azure is awful');
    });
    /*
    return new Promise((resolve, reject) => {
        const provider = new OAuthProvider('microsoft.com');
        signInWithPopup(auth,provider).then(function (result) {
            const user = result.user;
            console.log(user);
            document.getElementById('user-name').innerText = user.displayName;
            resolve(user);
        }).catch(function (error) {
            console.error(error);
            reject(error);
        });
    });
    */
}

export function signInWithPassword(email, password) {
    console.log('Signing in with password');
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, email, password).then(async function (result) {
            console.log(`Authed with ${result.user.email}`);
            const token = await result.user.getIdToken(true);
            postIdTokenToSessionLogin(token).then((data) => {
                console.log(data);
                resolve(result.user);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        }).catch(function (error) {
            console.error(error);
            if (error.code === 'auth/wrong-password') {
                reject('Wrong password');
            } else if (error.code === 'auth/user-not-found') {
                reject('User not found');
            } else if (error.code === 'auth/invalid-credential') {
                reject('Invalid credential');
            } else if (error.code === 'auth/invalid-email') {
                reject('Invalid email');
            } else {
                reject('Unknown error');
            }
        });
    });
}

export function signInWithToken(token) {
    return new Promise((resolve, reject) => {
        signInWithCustomToken(auth, token).then(function (result) {
            console.log(`Authed with ${result.user.email}`);
            resolve(result.user);
        }).catch(function (error) {
            console.error(error);
            reject(error);
        });
    });
}